import { css, Global } from "@emotion/react";
import ColorSchemeContext, {
  ColorSchemeMode,
} from "@hl/base-components/lib/theme/ColorSchemeContext";
import { darkTheme } from "@hl/base-components/lib/theme/dark/theme";
import { lightTheme } from "@hl/base-components/lib/theme/light/theme";
import { useColorScheme } from "@hl/base-components/lib/theme/useColorScheme";
import { useAccountSettings } from "@hl/shared-features/lib/features/auth/hooks/useAccountSettings";
import { MantineProvider } from "@mantine/core";

import fonts from "../fonts";

const globalStyles = css`
  ${fonts}
  .no-child-table .mantine-ScrollArea-viewport > div {
    display: unset !important;
  }
`;

export const AppThemeProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { enableDarkMode, userColorScheme, updateUserColorScheme } =
    useAccountSettings();

  const { colorScheme, userColorSchemeSettings, selectUserColorScheme } =
    useColorScheme({
      userColorScheme,
      updateUserColorScheme,
    });

  return (
    <ColorSchemeContext.Provider
      value={{
        colorScheme: colorScheme as ColorSchemeMode,
        userColorSchemeSettings,
        selectUserColorScheme,
      }}
    >
      <MantineProvider
        theme={
          colorScheme === ColorSchemeMode.DARK && enableDarkMode
            ? darkTheme
            : lightTheme
        }
        withGlobalStyles
        withNormalizeCSS
      >
        <Global styles={globalStyles} />
        {children}
      </MantineProvider>
    </ColorSchemeContext.Provider>
  );
};
